<template>
<v-col cols="12" class="py-0 bg-brilliant">
 
  <v-row style="padding-bottom:70px;">
    <v-col cols="11" sm="11" md="10" lg="7" class="mx-auto  header-section text-center mt-0 mb-5">
        <v-img src="../assets/img/homepage/coffrepirate.png" class="d-none d-sm-inline-flex" width="230"/>
        <v-img src="../assets/img/homepage/coffrepirate.png" class="d-inline-flex d-sm-none" width="150"/>
        
        <h1 class="font-main pb-1 pt-0 mb-5 pb-5 yellow--text text--darken-2">
          Félicitations !
        </h1>
        <br><v-divider></v-divider><br>
        <h3 class="font-main mb-5 pb-5 mt-0">
          Vous avez trouvé le mot de passe !
        </h3>
        <br><v-divider></v-divider><br><br>
        <div class="text-left font-papyrus">
          Vous pouvez accéder dès maintenant à l'ensemble du site, et même créer votre compte !<br><br>
          L'application est actuellement en phase de test, mais sera bientôt ouverte à tous.<br><br> 
          Nous profitons de ces derniers jours pour régler les derniers détails.<br><br>
          N'hésitez pas à nous faire parvenir vos remarques, et à nous poser vos questions si vous en avez. <br><br>
          Nous les utiliserons pour créer une rubrique "Foire Aux Questions", afin de guider au mieux les premiers utilisateurs,
          quand le site sera complètement ouvert.
          <br><br>
          <v-divider></v-divider><br>
          <span class="primary--text">La période de test prendra fin le 14 juillet 2023, 
          date à laquelle l'ensemble du système sera réinitialisé.<br><br>
          Vous pouvez donc tester l'application sans conséquence jusqu'à cette date ! </span><br><br>
          Profites-en pour explorer la Monnaie Pirate dans les moindre détails, 
          et commencer à recruter des pirates autour de vous, 
          en leur partageant le mot de passe que vous connaissez maintenant...
          <br><br>
          Merci, et bon vent jeune pirate !
          <br><br>
          <div class="text-right pr-4">
          Black Sam & Alvilda
          </div><br><br>
          <v-divider></v-divider><br>

        </div>

        <v-toolbar color="transparent" elevation="0">
          <v-spacer></v-spacer>
          <v-btn color="primary" class="black--text" to="/">
            Accéder au site <v-icon class="ml-1">mdi-arrow-right-circle</v-icon>
          </v-btn>
        </v-toolbar>
    </v-col>

  </v-row>

  <v-row>
    <v-col class="pa-0">
      <Footer v-if="showFooter"/>
    </v-col>
  </v-row>

</v-col>
</template>

<style>
</style>

<script>

import '@/assets/css/home.css';

import Footer from '@/components/main-layout/Footer.vue'

export default {
  name: 'home',
  components: {
    Footer
  },
  data: () => ({
    showTopInfoBar: true,
    showFooter: true,
    plans: [],
    pwd: "",
    pwdError: false
  }),
  mounted: async function(){
    this.$store.dispatch('app/incStat', '/unlock')
  },
  methods: {
   
  },
}
</script>
